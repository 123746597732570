import * as React from 'react';
import { Global, css } from '@emotion/core';
import {
  VectrexWOFF,
  VectrexWOFF2,
  MarthaWOFFRegular,
  MarthaWOFF2Regular,
  MarthaWOFFBold,
  MarthaWOFF2Bold,
  VectrexWOFF2Regular,
  VectrexWOFF2World,
  VectrexWOFFRegular,
  VectrexWOFFWorld,
} from '../fonts/fonts';

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Vectrex';
        src: url(${VectrexWOFF2}) format('woff2'),
          url(${VectrexWOFF}) format('woff');
        font-weight: 700;
        font-display: swap;
      }

      @font-face {
        font-family: 'Vectrex';
        src: url(${VectrexWOFF2Regular}) format('woff2'),
          url(${VectrexWOFFRegular}) format('woff');
        font-weight: 400;
        font-display: swap;
      }

      @font-face {
        font-family: 'Vectrex';
        src: url(${VectrexWOFF2World}) format('woff2'),
          url(${VectrexWOFFWorld}) format('woff');
        font-weight: 200;
        font-display: swap;
      }

      @font-face {
        font-family: 'Martha';
        src: url(${MarthaWOFF2Regular}) format('woff2'),
          url(${MarthaWOFFRegular}) format('woff');
        font-weight: 400;
        font-display: swap;
      }

      @font-face {
        font-family: 'Martha';
        src: url(${MarthaWOFF2Bold}) format('woff2'),
          url(${MarthaWOFFBold}) format('woff');
        font-weight: bold;
        font-display: swap;
      }
    `}
  />
);

export default Fonts;
