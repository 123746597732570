import * as React from 'react';
export const Asterisk = () => {
  return (
    <svg
      width="471"
      height="472"
      viewBox="0 0 471 472"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M471 243.228V228.558H253.259L406.913 74.1314L396.875 64.0937L243.221 217.748V0.00683594H228.551V217.748L74.1246 64.0937L64.0869 74.1314L217.741 227.786H0V242.456L217.741 243.228L64.0869 396.882L74.1246 406.92L227.779 253.266V471.007H242.449V253.266L396.875 406.92L406.913 396.882L253.259 243.228H471Z"
        fill="var(--primary)"
      />
    </svg>
  );
};
