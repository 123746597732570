/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment } from 'react';
import { Marquee, ParallelMarquee } from './Marquee';
import { Globe } from './glyphs/Globe';
import { Asterisk } from './glyphs/Asterisk';
import { Rise } from './glyphs/Rise';
import { Village } from './glyphs/Village';

interface ParallelMarqueeLayoutProps {
  parallelSlot: React.ReactNode;
  imageSrc?: string;
  imageSrc2?: string;
  parallelImageSrc?: string;
  parallelImageSrc2?: string;
}
const ParallelMarqueeLayout: React.FC<ParallelMarqueeLayoutProps> = ({
  children,
  parallelSlot,
  imageSrc,
  imageSrc2,
  parallelImageSrc,
  parallelImageSrc2,
}) => {
  const isImageEmpty = !imageSrc || imageSrc.includes('placeholder');
  const isParallelImageEmpty =
    !parallelImageSrc || parallelImageSrc.includes('placeholder');

  return (
    <div css={{ display: 'flex', height: '100%' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '66.6666%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Marquee />
        <div
          css={{
            display: 'flex',
            flex: 1,
          }}
        >
          <div
            css={{
              width: '40%',
              maxWidth: '40%',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              svg: {
                padding: '28px',
                width: '100%',
                height: 'auto',
                maxHeight: '40vh',
              },
            }}
          >
            {isImageEmpty && (
              <Fragment>
                <Globe />
                <Asterisk />
              </Fragment>
            )}

            {!isImageEmpty && (
              <div
                css={{
                  height: imageSrc2 ? '50%' : '100%',
                  flex: 1,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${imageSrc})`,
                }}
              />
            )}
            {imageSrc2 && (
              <div
                css={{
                  height: '50%',
                  flex: 1,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${imageSrc})`,
                }}
              />
            )}
          </div>

          <div
            sx={{
              width: '60%',
              maxWidth: '60%',
              margin: ['10px', '50px'],
            }}
          >
            {children}
          </div>
        </div>
        <Marquee flip />
      </div>
      <div css={{ width: '33.33333%', height: '100%' }}>
        <div
          css={{
            background: 'var(--primary)',
            display: 'grid',
            height: '100vh',
            gridTemplateRows: 'min-content 3fr 2fr min-content',
          }}
        >
          <ParallelMarquee />
          <div
            sx={{
              margin: ['10px', '50px'],
            }}
          >
            {parallelSlot}
          </div>
          <div css={{ display: 'flex' }}>
            {isParallelImageEmpty && (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  svg: {
                    '--primary': 'var(--secondary)',
                    height: 'auto',
                    width: '50%',
                    padding: '28px',
                  },
                }}
              >
                <Rise />
                <Village />
              </div>
            )}
            {!isParallelImageEmpty && (
              <div
                css={{
                  height: '100%',
                  flex: 1,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${parallelImageSrc})`,
                }}
              />
            )}
            {parallelImageSrc2 && (
              <div
                css={{
                  height: '100%',
                  flex: 1,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${parallelImageSrc2})`,
                }}
              />
            )}
          </div>
          <ParallelMarquee flip />
        </div>
      </div>
    </div>
  );
};

export { ParallelMarqueeLayout };
