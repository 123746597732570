/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
  memo,
  FC,
} from 'react';
import { Global } from '@emotion/core';
import { graphql, PageProps } from 'gatsby';
import add from 'date-fns/add';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import format from 'date-fns/format';
import { useHotkeys } from 'react-hotkeys-hook';
import queryString from 'query-string';
import Fonts from '../components/Fonts';
import { DoubleMarqueeLayout } from '../components/interstitial/DoubleMarqueeLayout';
import { ParallelMarqueeLayout } from '../components/interstitial/ParallelMarqueeLayout';
import { TalkCard } from '../components/interstitial/TalkCard';

type Program = {
  resume: string;
  speaker_1_name: string;
  speaker_2_name: string;
  start_date_1: string;
  start_date_2: string;
  start_date_3: string;
  title: string;
  duration: string;
  kind: string;
  format: string;
  speaker_1_photo: string;
  speaker_2_photo: string;
};

type ProgramConnection = {
  edges: {
    node: Program;
  }[];
};
type InterstitialPageProps = PageProps<{
  allProgramJson: ProgramConnection;
  parallel: ProgramConnection;
  allImageSharp: {
    edges: {
      node: {
        fluid: {
          src: string;
        };
      };
    }[];
  };
}>;

function titleCase(s: string) {
  return `${s[0].toUpperCase()}${s.slice(1)}`;
}

const DebugSchedule: FC<{
  flatPrograms: any;
  currentIndex: number;
}> = memo(({ flatPrograms, currentIndex }) => {
  return (
    <div sx={{ position: 'absolute', background: 'black', zIndex: 3 }}>
      <ul
        sx={{
          color: 'white',
          columnCount: 3,
          padding: 1,
        }}
      >
        {flatPrograms.map((node: any, index: number) => {
          const nodeDate = format(
            node.start_date,
            'yyyy-MM-dd~HH:mm:ss',
          ).replace('~', 'T');
          const isCurrentTalk = index === currentIndex;
          return (
            <li
              key={`${node.title}__${nodeDate}_${index}`}
              sx={{
                background: isCurrentTalk ? 'white' : undefined,
                color: isCurrentTalk ? 'black' : undefined,
              }}
            >
              {node.title} - {nodeDate} - {node.parallelTalk ? '(P)' : ''}
            </li>
          );
        })}
      </ul>
    </div>
  );
});

function delorean(secondsElapsed: number, currentDate?: string) {
  const futureDate = currentDate ? new Date(currentDate) : new Date();
  const diff = differenceInSeconds(futureDate, new Date());

  const nextDate = add(new Date(), {
    seconds: secondsElapsed + diff,
  });
  return nextDate;
}

const InterstitialPage = (props: InterstitialPageProps) => {
  const secondsElapsed = useRef(0);
  const params = queryString.parse(props.location.search);
  const [isTimeShown, setTimeShown] = useState(false);
  const [isScheduleShown, setIsScheduleShown] = useState(false);
  const [isManualOverride, setManualOverride] = useState(false);
  // Mock the currentTime
  const mockDate = useMemo(() => {
    return (
      (params.mockDate as string) ||
      format(new Date(), 'yyyy-MM-dd~HH:mm:ss').replace('~', 'T')
    );
  }, []);

  const [currentDate, setCurrentDate] = useState(
    delorean(secondsElapsed.current, mockDate),
  );

  const flatPrograms = useMemo(() => {
    const programs = props.data.allProgramJson.edges;
    const parallel = props.data.parallel.edges;
    const extractAndAugmentProgram = (
      node: Program,
      dateKey: 'start_date_1' | 'start_date_2' | 'start_date_3',
    ) => {
      let maybeParallelTalk = parallel.find(p => {
        return p.node[dateKey] === node[dateKey];
      });

      // Manually binding some talks together due to weird scheduling shenannigans
      if (node.title.toUpperCase().includes('LEADS TO EXPLOITATIVE DESIGN')) {
        maybeParallelTalk = parallel.find(p => {
          return p.node.title
            .toUpperCase()
            .includes('DIGITAL LEAN THINKING INSIDE EDUCATION');
        });
      }

      if (
        node.title
          .toUpperCase()
          .includes('DESIGNING FOR TRUST IN THE POST-TRUTH ERA')
      ) {
        maybeParallelTalk = parallel.find(p => {
          return p.node.title
            .toUpperCase()
            .includes('HOW TO LEAD A TEAM IN PERILOUS TIMES');
        });
      }

      if (
        node.title
          .toUpperCase()
          .includes('USING SEX AND PORN ONLINE TO CHANGE THE WORLD')
      ) {
        maybeParallelTalk = parallel.find(p => {
          return p.node.title
            .toUpperCase()
            .includes(
              'HOW DO WE INTERACT WHEN WE CAN’T TOUCH OR GET OUT OF OUR HOUSE',
            );
        });
      }
      const start_date = new Date(node[dateKey]);

      return {
        ...node,
        start_date,
        parallelTalk: maybeParallelTalk
          ? {
              ...maybeParallelTalk.node,
              end_time: add(start_date, {
                minutes: Number.parseInt(maybeParallelTalk.node.duration, 10),
              }),
            }
          : null,
      };
    };

    const day1 = programs.map(({ node }) =>
      extractAndAugmentProgram(node, 'start_date_1'),
    );
    const day2 = programs.map(({ node }) =>
      extractAndAugmentProgram(node, 'start_date_2'),
    );
    const day3 = programs.map(({ node }) =>
      extractAndAugmentProgram(node, 'start_date_3'),
    );

    const joinedDays = [...day1, ...day2, ...day3].sort((a, b) => {
      return a.start_date.getTime() - b.start_date.getTime();
    });

    return joinedDays;
  }, [props.data.allProgramJson.edges, props.data.parallel.edges]);

  const initialIndex = useMemo(() => {
    const d = delorean(0, mockDate);
    const nextIndex = flatPrograms.findIndex(node => {
      return d.getTime() < node.start_date.getTime();
    });

    return nextIndex === -1 ? 0 : nextIndex;
  }, [flatPrograms]);

  const [currentTalkIndex, setCurrentTalkIndex] = useState(initialIndex);

  const imageMap = useMemo(() => {
    const nextImageMap: Record<string, any> = {};
    for (const { node: image } of props.data.allImageSharp.edges) {
      const imageName = image.fluid.src.split('/').pop();
      if (!imageName) {
        continue;
      }

      nextImageMap[imageName] = image.fluid;
    }
    return nextImageMap;
  }, [props.data.allImageSharp]);

  useEffect(() => {
    const nextIndex = currentTalkIndex + 1;
    const nextProgram = flatPrograms[nextIndex];

    const pid = setInterval(() => {
      if (nextIndex >= flatPrograms.length - 1) {
        setCurrentTalkIndex(flatPrograms.length - 1);
        // We went over. Talk is done, clear the timer
        clearInterval(pid);
      }

      if (
        nextProgram &&
        currentDate.getTime() >= nextProgram.start_date.getTime()
      ) {
        setCurrentTalkIndex(nextIndex);
      }

      if (!isManualOverride) {
        secondsElapsed.current = secondsElapsed.current + 1;
        setCurrentDate(delorean(secondsElapsed.current, mockDate));
      }
    }, 1000);

    return () => {
      clearInterval(pid);
    };
  }, [
    currentDate,
    currentTalkIndex,
    flatPrograms,
    props.data.allProgramJson.edges,
    secondsElapsed,
  ]);

  const currentTalk = flatPrograms[currentTalkIndex];

  // Increment by 1 minute
  useHotkeys('m', () => {
    secondsElapsed.current = secondsElapsed.current + 60;
  });

  // Increment by 15 minutes
  useHotkeys('q', () => {
    secondsElapsed.current = secondsElapsed.current + 60 * 15;
  });

  // Increment by 60 minutes
  useHotkeys('h', () => {
    secondsElapsed.current = secondsElapsed.current + 60 * 60;
  });

  useHotkeys('j', () => {
    setManualOverride(true);
    setCurrentTalkIndex(s => {
      const nextIndex = s + 1;
      if (nextIndex > flatPrograms.length - 1) {
        return 0;
      }
      return nextIndex;
    });
  });

  useHotkeys('k', () => {
    setManualOverride(true);
    setCurrentTalkIndex(s => {
      const nextIndex = s - 1;
      if (nextIndex < 0) {
        return flatPrograms.length - 1;
      }

      return nextIndex;
    });
  });

  // Show debug Schedule
  useHotkeys('s', () => {
    setIsScheduleShown(s => !s);
  });

  // Show the current app time
  useHotkeys('t', () => {
    setTimeShown(s => !s);
  });

  return (
    <Fragment>
      <Fonts />
      <Global
        styles={{
          'html, body, #___gatsby, #gatsby-focus-wrapper, #gatsby-focus-wrapper > div': {
            height: '100%',
          },
        }}
      />
      <div
        css={{
          '--primary': '#230055',
          '--secondary': '#ff00ff',
          '--parallel-primary': '#ff00ff',
          '--parallel-secondary': '#230055',
          color: 'var(--primary)',
          backgroundColor: 'var(--secondary)',
        }}
      >
        {isScheduleShown && (
          <DebugSchedule
            flatPrograms={flatPrograms}
            currentIndex={currentTalkIndex}
          />
        )}
        {currentTalk.parallelTalk?.title && (
          <ParallelMarqueeLayout
            imageSrc={imageMap[currentTalk?.speaker_1_photo]?.src}
            imageSrc2={imageMap[currentTalk?.speaker_2_photo]?.src}
            parallelImageSrc={
              imageMap[currentTalk?.parallelTalk.speaker_1_photo]?.src
            }
            parallelImageSrc2={
              imageMap[currentTalk?.parallelTalk.speaker_2_photo]?.src
            }
            parallelSlot={
              <div
                css={{
                  '--primary': 'var(--parallel-primary)',
                  '--secondary': 'var(--parallel-secondary)',
                  '--talkcard__talktype': '3vh',
                  '--talkcard__duration': '1.5vh',
                  '--talkcard__title': '4vh',
                  '--talkcard__authors': '3vh',
                }}
              >
                <TalkCard
                  title={currentTalk.parallelTalk?.title}
                  authors={[
                    currentTalk.parallelTalk?.speaker_1_name,
                    currentTalk.parallelTalk?.speaker_2_name,
                  ]}
                  duration={currentTalk.parallelTalk?.format}
                  talkType={
                    currentTalk?.parallelTalk?.kind &&
                    titleCase(currentTalk?.parallelTalk?.kind)
                  }
                />
              </div>
            }
          >
            <TalkCard
              title={currentTalk.title}
              authors={[currentTalk.speaker_1_name]}
              description={currentTalk.resume}
              duration={currentTalk.format}
              talkType={titleCase(currentTalk.kind)}
            />
          </ParallelMarqueeLayout>
        )}
        {!currentTalk.parallelTalk?.title && (
          <DoubleMarqueeLayout
            imageSrc={imageMap[currentTalk?.speaker_1_photo]?.src}
            imageSrc2={imageMap[currentTalk?.speaker_2_photo]?.src}
          >
            <TalkCard
              title={currentTalk.title}
              authors={[currentTalk.speaker_1_name, currentTalk.speaker_2_name]}
              description={currentTalk.resume}
              duration={currentTalk.format}
              talkType={titleCase(currentTalk.kind)}
            />
          </DoubleMarqueeLayout>
        )}

        {isTimeShown && (
          <div
            sx={{
              position: 'fixed',
              fontFamily: 'Vectrex',
              right: 0,
              bottom: '10%',
              background: 'black',
              color: 'white',
              padding: '10px',
              zIndex: 10,
              width: '300px',
              maxHeight: '80px',
            }}
          >
            Now&nbsp;: {format(currentDate, 'yyyy-MM-dd HH:mm:ss')}
            <br />
            Next:{' '}
            {format(
              flatPrograms[currentTalkIndex + 1]?.start_date || new Date(),
              'yyyy-MM-dd HH:mm:ss',
            )}
            <br />
            {isManualOverride ? 'Timer is paused!' : 'Timer is active'}
            <br />
            {currentTalkIndex + 1} on {flatPrograms.length - 1} talks completed
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default InterstitialPage;

export const pagesQuery = graphql`
  fragment commonInterstitialFields on ProgramJsonConnection {
    edges {
      node {
        resume
        speaker_1_name
        speaker_2_name
        start_date_1
        start_date_2
        start_date_3
        title
        duration
        kind
        format
        speaker_1_photo
        speaker_2_photo
      }
    }
  }

  query InterstitialPageQuery {
    allProgramJson(
      filter: {
        start_date_2: { gte: "0" }
        start_date_3: { gt: "0" }
        kind: { in: ["keynote", "talk", "panel", "ixda"] }
      }
    ) {
      ...commonInterstitialFields
    }
    parallel: allProgramJson(
      filter: {
        start_date_2: { gte: "0" }
        start_date_3: { gt: "0" }
        kind: { nin: ["keynote", "talk", "panel", "ixda"] }
      }
    ) {
      ...commonInterstitialFields
    }
    allImageSharp {
      edges {
        node {
          fluid {
            src
          }
        }
      }
    }
  }
`;
