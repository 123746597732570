/** @jsx jsx */
import { jsx } from 'theme-ui';
import { keyframes } from '@emotion/core';

const marquee = keyframes`
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
`;

const Marquee: React.FC<{ flip?: boolean }> = ({ flip }) => {
  return (
    <div
      css={{
        background: 'var(--primary)',
        color: 'var(--secondary)',
        fontFamily: 'Vectrex',
        position: 'relative',
        overflow: 'hidden',
        '--offset': '20vw',
        '--move-initial': 'calc(-25.7% + var(--offset))',
        '--move-final': 'calc(-50% + var(--offset))',
        span: {
          fontSize: '3vw',
          padding: '0 1vw',
        },
        'span:nth-of-type(odd)': {
          fontWeight: 200,
        },
        'span:nth-of-type(even)': {
          fontWeight: 700,
        },
        '.marquee_inner': {
          width: 'fit-content',
          display: 'flex',
          position: 'relative',
          padding: '10px 0',
          alignItems: 'center',
          transform: 'translate3d(var(--move-initial), 0, 0)',
          animation: `${marquee} 5s linear infinite`,
          animationPlayState: 'running',
        },
        transform: flip ? 'rotate(180deg)' : undefined,
      }}
    >
      <div className="marquee_inner" aria-hidden="true">
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
        <span>UP&nbsp;NEXT</span>
        <span>*</span>
      </div>
    </div>
  );
};

const ParallelMarquee: React.FC<{ flip?: boolean }> = ({ flip }) => {
  return (
    <div
      css={{
        background: 'var(--parallel-primary)',
        color: 'var(--parallel-secondary)',
        fontFamily: 'Vectrex',
        position: 'relative',
        overflow: 'hidden',
        '--offset': '20vw',
        '--move-initial': 'calc(-25.7% + var(--offset))',
        '--move-final': 'calc(-50% + var(--offset))',
        span: {
          fontSize: '3vw',
          padding: '0 1vw',
        },
        'span:nth-of-type(odd)': {
          fontWeight: 200,
        },
        'span:nth-of-type(even)': {
          fontWeight: 700,
        },
        '.marquee_inner': {
          width: 'fit-content',
          display: 'flex',
          position: 'relative',
          padding: '10px 0',
          alignItems: 'center',
          transform: 'translate3d(var(--move-initial), 0, 0)',
          animation: `${marquee} 5s linear infinite`,
          animationPlayState: 'running',
        },
        transform: flip ? 'rotate(180deg)' : undefined,
      }}
    >
      <div className="marquee_inner" aria-hidden="true">
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
        <span>PARALLEL</span>
        <span>*</span>
      </div>
    </div>
  );
};

export { Marquee, ParallelMarquee };
