import * as React from 'react';
export const Globe = () => {
  return (
    <svg
      width="483"
      height="483"
      viewBox="0 0 483 483"
      preserveAspectRatio="xMinYMin"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M241.495 476.007C371.008 476.007 475.999 371.016 475.999 241.503C475.999 111.991 371.008 7 241.495 7C111.983 7 6.99219 111.991 6.99219 241.503C6.99219 371.016 111.983 476.007 241.495 476.007Z"
        stroke="var(--primary)"
        stroke-width="13"
        stroke-miterlimit="10"
      />
      <path
        d="M241.495 476.007C300.559 476.007 348.44 371.016 348.44 241.503C348.44 111.991 300.559 7 241.495 7C182.43 7 134.549 111.991 134.549 241.503C134.549 371.016 182.43 476.007 241.495 476.007Z"
        stroke="var(--primary)"
        stroke-width="13"
        stroke-miterlimit="10"
      />
      <path
        d="M387.723 424.814C347.631 392.793 296.793 373.645 241.485 373.645C186.178 373.645 135.351 392.793 95.248 424.814"
        stroke="var(--primary)"
        stroke-width="13"
        stroke-miterlimit="10"
      />
      <path
        d="M241.496 7V476.007"
        stroke="var(--primary)"
        stroke-width="13"
        stroke-miterlimit="10"
      />
      <path
        d="M6.99219 241.503H475.999"
        stroke="var(--primary)"
        stroke-width="13"
        stroke-miterlimit="10"
      />
      <path
        d="M387.734 58.1807C347.643 90.2015 296.805 109.35 241.497 109.35C186.189 109.35 135.363 90.2015 95.2598 58.1807"
        stroke="var(--primary)"
        stroke-width="13"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
