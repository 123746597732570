/** @jsx jsx */
import { jsx } from 'theme-ui';

interface TalkCardProps {
  title: string;
  authors: string[];
  description?: string;
  duration?: string;
  talkType: string;
  registrationRequired?: boolean;
}
const TalkCard: React.FC<TalkCardProps> = ({
  talkType,
  duration,
  title,
  authors,
  description,
  registrationRequired,
}) => {
  const cleanedAuthors = authors.map(a => a.replace(',', '')).filter(Boolean);
  const nextAuthors = cleanedAuthors.join(', ').trim();
  return (
    <div
      sx={{
        color: 'var(--primary)',
        '.talkcard__header': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: ['10px', '20px', '40px'],
        },
        '.talkcard__header__talktype': {
          fontFamily: 'Martha',
          fontSize: 'var(--talkcard__talktype, 5.5vh)',
          fontWeight: 700,
          marginRight: ['15px', '15px', '30px'],
        },
        '.talkcard__header__duration, .talkcard__header__registration': {
          fontFamily: 'Martha',
          fontSize: 'var(--talkcard__duration, 2.0vh)',
          padding: '6px 6px',
          backgroundColor: 'var(--primary)',
          color: 'var(--secondary)',
        },
        '.talkcard__title': {
          fontFamily: 'Vectrex',
          fontSize: 'var(--talkcard__title, 5.5vh)',
          textTransform: 'uppercase',
          marginBottom: '3vh',
        },
        '.talkcard_authors': {
          fontFamily: 'Martha',
          fontSize: 'var(--talkcard__authors, 5vh)',
          fontWeight: 700,
          marginBottom: '3vh',
        },
        '.talkcard__description': {
          fontFamily: 'Martha',
          fontSize: '3vh',
          maxWidth: '90%',
        },
      }}
    >
      <div className="talkcard__header">
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div className="talkcard__header__talktype">{talkType}</div>
          {duration && (
            <div className="talkcard__header__duration">{duration}</div>
          )}
        </div>
        {registrationRequired && (
          <div className="talkcard__header__registration">
            Registration Required
          </div>
        )}
      </div>
      <div className="talkcard__title">{title}</div>
      <div className="talkcard_authors">{nextAuthors}</div>
      <div className="talkcard__description">{description}</div>
    </div>
  );
};

export { TalkCard };
