/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment } from 'react';
import { Marquee } from './Marquee';
import { Globe } from './glyphs/Globe';
import { Asterisk } from './glyphs/Asterisk';

interface DoubleMarqueeLayoutProps {
  imageSrc?: string;
  imageSrc2?: string;
}
const DoubleMarqueeLayout: React.FC<DoubleMarqueeLayoutProps> = ({
  children,
  imageSrc,
  imageSrc2,
}) => {
  const isImageEmpty = !imageSrc || imageSrc.includes('placeholder');

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Marquee />
      <div
        css={{
          display: 'flex',
          flex: 1,
        }}
      >
        <div
          sx={{
            width: '60%',
            margin: ['10px', '50px'],
          }}
        >
          {children}
        </div>
        <div
          css={{
            width: '40%',
            maxWidth: '40%',
            height: '100%',
          }}
        >
          <div
            css={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              svg: {
                padding: '28px',
                width: 'auto',
                height: '40vh',
              },
            }}
          >
            {isImageEmpty && (
              <Fragment>
                <Globe />
                <Asterisk />
              </Fragment>
            )}
            {!isImageEmpty && (
              <div
                css={{
                  height: imageSrc2 ? '50%' : '100%',
                  width: '100%',
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${imageSrc})`,
                }}
              />
            )}
            {imageSrc2 && (
              <div
                css={{
                  height: '50%',
                  width: '100%',
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${imageSrc2})`,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Marquee flip />
    </div>
  );
};

export { DoubleMarqueeLayout };
